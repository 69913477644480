<template>
  <base-info-card 
        title="Perfil"
        subtitle="Datos Fiscales"
        >
            <div class="contacto">
                <v-row>
                    <v-col class="text-right">
                    <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                    content-class="dialog-class pa-5"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            >
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                        <fiscalform :form="form" @closeit="dialog = false" @editedContact="editContact" />
                    </v-dialog>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Nombre Fiscal
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.name}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        RFC
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.rfc}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Calle y Numero
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.address}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Colonia
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.address2}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Ciudad
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.city}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Estado
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.state}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Pais
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.country}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        Codigo Postal
                    </v-col>
                    <v-spacer />
                    <v-col class="text-subtitle-2">
                        {{form.zip}}
                    </v-col>
                </v-row>
                
            </div>
      </base-info-card>
</template>

<script>
import fiscalform from './fiscalform.vue';
export default {
  components: { fiscalform },
    props:{
            form:{
                Type: Object,
                default:{}
            }
        }
    ,data: ()=> ({
        dialog: false,
    })
    ,methods:{
        editContact(){
            this.dialog = false;
            this.$emit('editContact');
        },
    }
}
</script>

<style>
.dialog-class{
    background-color: ghostwhite;
}
</style>